.projects {
  background-color: #1b3d47;
  display: block;
  padding: 7vh;
}

.projects__title {
  padding-bottom: 6%;
  padding-top: 2%;
  font-size: 2.4rem;
  color: ghostwhite;
}

.projects__content {
  padding-left: 7%;
  color: #1b3d48;
}

.description {
  font-size: 0.7rem;
  color: white;
}

.card-title {
  color: white;
}

.subtitle {
  font-size: 0.8rem;
}

.card-img-top {
  width: 100%;
  height: 55%;
  object-fit: cover;
}

.card-body {
  width: 100%;
}
.col-auto {
  margin-bottom: 4%;
}
@media screen and (max-width: 850px) {
  /*Same but for less*/
  .projects {
    background-color: #1b3d47;
    display: block;
    padding: 7%;
  }
  .projects__content {
    font-size: 1rem;
  }
  .projects__title {
    font-size: 2rem;
  }
}
