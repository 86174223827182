.contact {
  background-color: #1b3d47;
  padding-left: 7vh;
}

.contact__title {
  padding-bottom: 4%;
  padding-top: 3%;
  font-size: 2.4rem;
  color: ghostwhite;
}

.contact__parent {
  margin-bottom: 3%;
  width: 100%;
}
.contact__content {
  text-align: center;
  margin: auto;
}
.contact__logo {
  margin: 2%;
}

@media screen and (max-width: 850px) {
  /*Same but for less*/
  .contact {
    background-color: #1b3d47;
    display: block;
    padding: 7%;
  }
  .contact__content {
    font-size: 1rem;
  }
  .contact__title {
    font-size: 2rem;
  }
  .contact__logo {
    width: 25%;
    height: 25%;
    margin: 2%;
  }
}
