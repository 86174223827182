.skills {
  background-color: #1b3d47;
  padding-left: 7vh;
  padding-bottom: 4%;
}

.skills__title {
  padding-bottom: 4%;
  padding-top: 3%;
  font-size: 2.4rem;
  color: ghostwhite;
}

.skills__parent,
.skills__last {
  margin-bottom: 3%;
  width: 100%;
}

.languages {
  background-color: #1a6b84;
  color: #f1f1e6;
  padding: 1%;
  font-size: 1rem;
}

.skillLevel {
  background-color: #105a7b;
  padding: 1%;
}

.skillDisparity {
  background-color: #8dafbb;
}

@media screen and (max-width: 850px) {
  /*Same but for less*/
  .skills {
    background-color: #1b3d47;
    display: block;
    padding: 7%;
  }
  .skills__content {
    font-size: 1rem;
  }
  .skills__title {
    font-size: 2rem;
  }
  .languages {
    font-size: 0.5rem;
  }
  .skillLevel {
    font-size: 0.6rem;
  }
}
