.about {
  background-color: #1b3d47;
}
.about__title {
  padding-left: 7vh;
  padding-bottom: 2%;
  padding-top: 3%;
  font-size: 2.4rem;
  color: ghostwhite;
}

.about__content {
  margin: 0%;
  padding-left: 7vh;
  padding-right: 7.4vh;
  color: #7c8c91;
}

@media screen and (max-width: 850px) {
  /*Same but for less*/
  .about__content {
    font-size: 0.8rem;
    padding-left: 7%;
    padding-right: 7%;
  }
  .about__title {
    padding-left: 7%;
    font-size: 2rem;
  }
}
