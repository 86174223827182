.parent {
  margin: auto;
  background-image: url(/Users/brettyang/Documents/personal-website/src/assets/background.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 5%;
}
.nav__content {
  padding-top: 10vw;
  padding-left: 7vh;
}
.navbar {
  height: 12%;
}

.nav__title {
  width: 70%;
  font-size: 4rem;
}
.nav__description {
  width: 50%;
  font-size: 2vw;
}
.left {
  margin: auto;
}
.title-img {
  width: 100%;
}
.navLink:hover {
  color: black;
  text-decoration: none;
}
.styling {
  background-color: #f9ab65;
  color: white;
  border: 1px solid #f9ab65;
  border-radius: 25px;
  padding: 2%;
  margin-top: 4%;
  font-size: 2rem;
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.3s;
  transition-property: transform;
}

.styling:hover,
.styling:focus,
.styling:active {
  transform: scale(1.1);
}

@media screen and (max-width: 850px) {
  /*Same but for less*/
  .styling {
    font-size: 1rem;
  }
  .nav__content {
    padding-top: 10vw;
    padding-left: 7%;
  }
  .nav__title {
    font-size: 2rem;
  }
  .nav__description {
    font-size: 0.5rem;
  }
}
